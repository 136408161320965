/* General App Styles */
.App {
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
}

h1, h2 {
  color: #0056b3;
}

p {
  color: #666;
  font-size: 14px;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Search Form Styling */
.search {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 80%;
  max-width: 500px;
  margin: 20px auto;
  align-items: center;
}

.search input, .search button {
  width: 100%;
  padding: 12px 15px;
  margin-top: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
}

.search input:focus, .search button:focus {
  outline: none;
  border-color: #0056b3;
}

.search button {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: none;
}

.search button:hover {
  background-color: #0056b3;
}

/* Progress Bar Enhancements */
.progressBar {
  height: 20px;
  width: 0%;
  background-color: #4caf50;
  transition: width 0.5s ease;
  border-radius: 10px;
}

/* List Styling */
ul {
  list-style-type: none;
  padding: 0;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .search {
      width: 90%;
  }
}

.instructions {
  margin-top: 20px;
  font-size: 12px;
}

.instructions li {
  border: none;
  background-color: transparent;
}

.error {
  color: #D8000C;
  background-color: #FFD2D2;
  border: 1px solid #D8000C;
  padding: 10px;
  margin: 20px 0;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.article {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  text-align: left;
}

.top-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

small {
  font-size: 12px;
  color: #444;
}

mark {
  background-color: rgb(255, 217, 0);
  padding: 2px;
}

.search-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0 30px;
}



.years .checkbox-container {
  margin-right: 20px;
}

.years .checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px;
}

.years .checkboxes label {
  margin: 5px 0;
}

/* Enhance alignment and spacing around buttons */
.years button {
  margin-top: 15px;
}

.years .button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px; /* Space between buttons */
  margin-top: 10px;
}

.years .button-container button {
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  margin: 0;
}

.years .button-container button:hover {
  background-color: #0056b3;
}

.years input[type="checkbox"] {
  accent-color: #007bff; /* Use your main color theme for the checkbox */
  margin-right: 5px;
}

label {
  margin: 0;
  font-size: 16px;
  color: #333; /* Dark color for better readability */
}
